<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_f9e9644219d3f75f')}}</span>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								
							</el-col>
						</el-row>
					</div>
					<div class="filterCon" style="">
						<ul class="filterConList">
						  <!-- <el-button type="success" size="small" icon="el-icon-search" @click="openWhCustomerSelData($event)">{{$t('i18nn_b83597729ab29694')}}</el-button>
							<span v-if="selUserId">
								<el-tag v-if="selUserName">{{selUserName}}</el-tag>
								<el-tag v-if="selUserId">{{selUserId}}</el-tag>
							</span> -->
							
							<li>
								
								<span>{{ $t('hytxs0000060') }}</span>
								<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
							</li>
							<!-- <span style="color:red;">{{$t('i18nn_07e0f8f59293e78d')}}</span> -->
						</ul>
						<!-- <el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button> -->
					</div>
					<!-- <el-tabs type="border-card" v-model="activeName"> -->
					  <!-- <el-tab-pane label="FBA中转配置" name="first">
							<div v-if="'first'==activeName">
								
								<GeneralCostConfig
									 :selUserId="selUserId"
									 :title="'FBA中转服务'"
									 :explain="'转运费用组成：卸货费+订单处理费+出库操作费+运费+打托费（如需）+仓储费（如需）+贴标费（如需）'"
									 :opModel="'WH-STF'"
									 :configKeyObj="{
										 'WH-STF-SUB-01': $t('i18nn_7e95458d2a2eef81'),
										 'WH-STF-SUB-02': $t('i18nn_4b98d0a614266407'),
										 'WH-STF-SUB-03': $t('i18nn_5ef418dc89a48756'),
										 'WH-STF-SUB-04': $t('i18nn_8766a8425c9fc7da'),
										 'WH-STF-AS-01': $t('i18nn_4b1a6ff16878595b'),
										 'WH-STF-AS-02': $t('i18nn_ecbdeee4451a9621'),
										 'WH-STF-AS-03': $t('i18nn_4fdda8fb0985d084'),
										 'WH-STF-AS-04': $t('i18nn_b43b2c2848adc46b'),
										 'WH-STF-AS-05': $t('i18nn_95fbe2b6d26e92bc'),
										 'WH-STF-AS-06': $t('i18nn_3fdd0d4d9a7310c2')
									 }"
								></GeneralCostConfig>
							</div>
						</el-tab-pane> -->
						<!-- <el-tab-pane :label="$t('i18nn_199f5bded0b23976')" name="second">
							<div v-if="'second'==activeName">
								<GeneralCostConfig
									 :selUserId="selUserId"
									 :title="$t('i18nn_199f5bded0b23976')"
									 :explain="'退货贴标费用组成：入库+开箱+清点+换标贴标费+打包费+出库+运费'"
									 :opModel="'WH-SRE'"
									 :configKeyObj="{
										 'WH-SRE-SUB-01': $t('hytxs0000012'),
										 'WH-SRE-SUB-02': $t('i18nn_4181240879167208'),
										 'WH-SRE-SUB-03': $t('i18nn_24a08f5b0d5b5b7b'),
										 'WH-SRE-SUB-04': $t('i18nn_4fdda8fb0985d084'),
										 'WH-SRE-SUB-05': $t('i18nn_4451ef6efa66781b'),
										 'WH-SRE-SUB-06': $t('i18nn_3155418abb05309e'),
										 'WH-SRE-SUB-07': $t('i18nn_7e5c4599cfa54cca'),
										 'WH-SRE-AS-01': 'SKU种类分拣',
										 'WH-SRE-AS-02': $t('i18nn_5ef418dc89a48756'),
										 'WH-SRE-AS-03': $t('i18nn_eb8383f1ad65f63a'),
										 'WH-SRE-AS-04': $t('i18nn_3f126633b4bfea56'),
										 'WH-SRE-AS-05': '添加/取出配件',
										 'WH-SRE-AS-06': $t('i18nn_61f33b788a44c915'),
										 'WH-SRE-AS-07': $t('i18nn_3fdd0d4d9a7310c2'),
										 'WH-SRE-AS-08': $t('i18nn_b43b2c2848adc46b'),
										 'WH-SRE-AS-09': $t('i18nn_570125be2fff2e6d'),
									 }"
								></GeneralCostConfig>
							</div>
							
						</el-tab-pane> -->
						<!-- <el-tab-pane :label="$t('684a2afb069b6016')" name="third">
							<div v-if="'third'==activeName">
								<GeneralCostConfig
									 :selUserId="selUserId"
									 :title="$t('684a2afb069b6016')"
									 :explain="'费用构成：入仓费+订单处理费+派送费+仓租费（如需）+打托费（如需）'"
									 :opModel="'WH-SDS'"
									 :configKeyObj="{
										 'WH-SDS-SUB-02':$t('i18nn_90656db9cda8e9e5'),
										 'WH-SDS-AS-01': $t('i18nn_5ef418dc89a48756'),
										 'WH-SDS-AS-02': $t('i18nn_4d02523e5d95b88b'),
										 'WH-SDS-AS-03': $t('i18nn_4fdda8fb0985d084'),
										 'WH-SDS-AS-04': $t('i18nn_4b1a6ff16878595b'),
										 'WH-SDS-AS-05': $t('i18nn_61f33b788a44c915'),
										 'WH-SDS-AS-06': $t('i18nn_b74a51743a2a06e3'),
										 'WH-SDS-AS-07': $t('i18nn_b43b2c2848adc46b'),
										 'WH-SDS-AS-08': $t('i18nn_570125be2fff2e6d'),
									 }"
								></GeneralCostConfig>
							</div>
							
						</el-tab-pane> -->
						<!-- <el-tab-pane :label="$t('i18nn_5b177a1b00c63503')" name="fourth">
							<div v-if="'fourth'==activeName">
								<PortTakeDeliveryService :selUserId="selUserId"></PortTakeDeliveryService>
							</div>
							
						</el-tab-pane> -->
						<!-- <el-tab-pane :label="$t('i18nn_f9abbe1d9c185407')" name="first"> -->
							<!-- <div v-if="'five'==activeName"> -->
								<PrtOrderExpress
									 :selUserId="selUserId"
									 :title="$t('i18nn_f9abbe1d9c185407')"
									 :explain="''"
									 :opModel="'wh_online_label'"
									 :configKeyObj="{
										 'wh_online_label_create': $t('i18nn_81f6eaf23b9c56eb'),
									 }"
								></PrtOrderExpress>
							<!-- </div> -->
							
						<!-- </el-tab-pane> -->
					  <!-- <el-tab-pane :label="$t('i18nn_73195b8b5e85d58a')">{{$t('i18nn_73195b8b5e85d58a')}}</el-tab-pane>
					  <el-tab-pane :label="$t('i18nn_41d67b94402699ed')">{{$t('i18nn_41d67b94402699ed')}}</el-tab-pane>
					  <el-tab-pane :label="$t('i18nn_41f35b4ad3af9df5')">{{$t('i18nn_41f35b4ad3af9df5')}}</el-tab-pane> -->
					<!-- </el-tabs> -->
				</div>
			</div>
		</div>
		<!-- <el-dialog :title="$t('7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
		  <div style="">
		    <WhCustomer :isSel="true" @selectRow="selWhCustomerData"></WhCustomer>
		  </div>
		  <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('4e9fc68608c60999')}}</el-button></div>
		</el-dialog> -->
	</div>
</template>
<script>
	// import WhCustomer from '@/components/WarehouseCenter/WhCustomer.vue';
// import WhGoods from './WhGoods.vue';
// import GeneralCostConfig from '@/components/WarehouseCenter2/CostConfig/GeneralCostConfig.vue';
// import FBATransferService from '@/components/WarehouseCenter2/CostConfig/FBATransferService.vue';
// import ReturnedGoodsService from '@/components/WarehouseCenter2/CostConfig/ReturnedGoodsService.vue';
// import DropShippingService from '@/components/WarehouseCenter2/CostConfig/DropShippingService.vue';
// import PortTakeDeliveryService from '@/components/WarehouseCenter2/CostConfig/PortTakeDeliveryService.vue';
import PrtOrderExpress from '@/components/WarehouseCenter2/CostConfig/PrtOrderExpress.vue';

// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';

export default {
	components: {
		// WhCustomer,
		cusSelFuzzy,
		// GeneralCostConfig,
		PrtOrderExpress,
		// FBATransferService,
		// ReturnedGoodsService,
		// DropShippingService,
		// PortTakeDeliveryService
	},
	data() {
		return {
			activeName: 'first',
			//打开选择框
			// dialogSelVisible: false,
			selUserId:"",
			// selUserName:"",
		};
	},
	activated() {
		
	},
	//创建时
	created() {
		
	},
	//编译挂载前
	mounted() {
		
	},
	methods: {
		initData() {
			
		},
		changCus(data) {
			console.log('changCus', data);
			// this.cusUserId = data.userId;
			// this.cusUserName = data.companyName;
			// this.initData();
			// this.dialogSelVisible = false;
			this.selUserId = data.userId;
			// this.selUserName = data.companyName;
		},
		//打开选择数据
		// openWhCustomerSelData(event) {
		//   event.stopPropagation();
		//   this.dialogSelVisible = true;
		// },
		//选择数据后回调
		// selWhCustomerData(selRow) {
		//   this.dialogSelVisible = false;
		//   this.selUserName = selRow.companyName;
		//   this.selUserId = selRow.userId;
		// },
		
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
