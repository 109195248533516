<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable" v-loading="loading" :element-loading-text="$t('47df8be257c59dde')">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{title}}</span>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<el-alert v-if="explain" :title="explain" type="success" :closable="false"></el-alert>

					<table class="simTable costTable" style="">
						<!-- <tr class="emphasize"><td colspan="8">一、基本服务收费</td></tr> -->
						<tr class="emphasize">
							<td width="20">{{$t('7b1c2b1adc920d9c')}}</td>
							<td width="100">{{$t('i18nn_669de9a6d032bb1f')}}</td>
							<td width="100"></td>
							<td width="100">价格$</td>
							<td width="100">折扣(0.9即9折)</td>
							<td width="200">{{$t('i18nn_d766c2e817285f0a')}}</td>
							<td width="100">{{$t('b6bf0a07fe26f74f')}}</td>
							<td width="300">{{$t('f925d9b48d8c1d45')}}</td>
						</tr>
						<tbody v-for="(itemPVal, itemPKey, indexP) in configGroupBase" :key="itemPKey">
							<tr v-for="(item, index) in itemPVal" :key="index">
								<td v-if="index == 0" :rowspan="itemPVal.length">{{ indexP + 1 }}</td>
								<td v-if="index == 0" :rowspan="itemPVal.length">{{ item.serviceTypeName }}</td>
								<td>{{ $Utils.i18nKeyText(item,'codeText') }}</td>
								<td><el-input-number v-model="item.servicePrice"></el-input-number></td>
								<td><el-input-number v-model="item.discount" :min="0" :step="0.1"></el-input-number></td>
								<td>
									
									<el-switch
									  v-model="item.isExpression"
									  :active-text="$t('i18nn_889b3ef0590d9f54')"
									  :inactive-text="$t('i18nn_2694c4443e3fe806')"
										active-value="1"
										inactive-value="0">
									</el-switch>
									<el-input v-if="1==item.isExpression" type="textarea" size="mini" v-model="item.feeExpression" :maxlength="1000" show-word-limit :placeholder="$t('i18nn_d766c2e817285f0a')"></el-input>
									<!-- <span style="word-break: break-all;">{{item.feeExpression}}</span> -->
								</td>
								<td><el-input v-model="item.unit" placeholder=""></el-input></td>
								<td>{{ item.remark }}</td>
							</tr>
						</tbody>

						<!-- <tbody>
							<tr class="emphasize"><td colspan="8">二、增值服务</td></tr>
							<tr class="emphasize">
								<td>{{$t('7b1c2b1adc920d9c')}}</td>
								<td colspan="2">{{$t('i18nn_669de9a6d032bb1f')}}</td>
								<td>价格$</td>
								<td>{{$t('i18nn_aec87e7239d5a6a6')}}</td>
								<td>{{$t('i18nn_d766c2e817285f0a')}}</td>
								<td>{{$t('b6bf0a07fe26f74f')}}</td>
								<td>{{$t('f925d9b48d8c1d45')}}</td>
							</tr>
						</tbody> -->

						<!-- <tbody v-for="(itemPVal, itemPKey, indexP) in configGroupAddFee" :key="itemPKey">
							<tr v-for="(item, index) in itemPVal" :key="index">
								<td v-if="index == 0" :rowspan="itemPVal.length">{{ indexP + 1 }}</td>
								<td v-if="index == 0" :rowspan="itemPVal.length">{{ item.serviceTypeName }}</td>
								<td>{{ $Utils.i18nKeyText(item,'codeText') }}</td>
								<td>
									<el-input-number v-model="item.servicePrice"></el-input-number>
								</td>
								<td><el-input-number v-model="item.discount" :min="0" :step="0.1"></el-input-number></td>
								<td>
									
									<el-switch
									  v-model="item.isExpression"
									  :active-text="$t('i18nn_889b3ef0590d9f54')"
									  :inactive-text="$t('i18nn_2694c4443e3fe806')"
										active-value="1"
										inactive-value="0">
									</el-switch>
									<el-input v-if="1==item.isExpression" type="textarea" size="mini" v-model="item.feeExpression" :placeholder="$t('i18nn_d766c2e817285f0a')"></el-input>
									
								</td>
								<td><el-input v-model="item.unit" placeholder=""></el-input></td>
								<td>{{ item.remark }}</td>
							</tr>
						</tbody> -->
					</table>

					<div style="margin-top: 10px;"><el-button type="primary" style="width: 200px;" @click="submitAction">{{$t('ad4345dbaabe1479')}}</el-button></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import WhGoods from './WhGoods.vue';
// import WhPlace from '@/components/WarehouseCenter/WhPlace.vue';
// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
export default {
	props: {
		selUserId: {
		  default: function() {
		    return '';
		  },
		  type: String
		},
		title: {
		  default: function() {
		    return '';
		  },
		  type: String
		},
		explain: {
		  default: function() {
		    return '';
		  },
		  type: String
		},
		opModel: {
		  default: function() {
		    return '';
		  },
		  type: String
		},
		configKeyObj: {
		  default: function() {
		    return {};
		  },
		  type: Object
		},
	},
	components: {
		// WhPlace,
		// cusSelFuzzy
	},
	data() {
		return {
			//表格数据
			loading: false,
			loading_load: false,

			//FBA MODAL KEY
			// opModel: 'WH-STF',
			// configKeyObj: {
			// 	'WH-STF-SUB-01': this.$t('i18nn_7e95458d2a2eef81'),
			// 	'WH-STF-SUB-02': this.$t('i18nn_4b98d0a614266407'),
			// 	'WH-STF-SUB-03': this.$t('i18nn_5ef418dc89a48756'),
			// 	'WH-STF-SUB-04': this.$t('i18nn_8766a8425c9fc7da'),
			// 	// 'WH-STF-SUB-05':'',
			// 	'WH-STF-AS-01': this.$t('i18nn_4b1a6ff16878595b'),
			// 	'WH-STF-AS-02': this.$t('i18nn_ecbdeee4451a9621'),
			// 	'WH-STF-AS-03': this.$t('i18nn_4fdda8fb0985d084'),
			// 	'WH-STF-AS-04': this.$t('i18nn_b43b2c2848adc46b'),
			// 	'WH-STF-AS-05': this.$t('i18nn_95fbe2b6d26e92bc'),
			// 	'WH-STF-AS-06': this.$t('i18nn_3fdd0d4d9a7310c2')
			// },
			configGroupBase: {
				
			},
			// configGroupAddFee: {
				
			// },
			selectOption: {
				
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc' //desc降序，asc升序

			}
		};
	},
	watch:{
		'selUserId':function(){
			this.initData();
		}
	},
	activated() {
		// this.initData();
	},
	//创建时
	created() {
		this.initData();
	},
	//编译挂载前
	mounted() {
		
	},
	methods: {
		initData() {
			if(this.selUserId){
				this.getDicData(()=>{
					this.getData();
				});
			}
			
		},

		//请求分页数据
		getData() {

			this.loading_load = true;
			this.$http
				.get(this.$urlConfig.whCostConfigQuery+'/'+this.selUserId + '?prefix=' + this.opModel, {})
				.then(({ data }) => {
					console.log(data);
					
					this.loading_load = false;
					if (200 == data.code && data.data) {
						let allKeyData = data.data;
						
						console.log('configGroupBase11',this.configGroupBase);
						
						console.log('allKeyData',allKeyData);
						
						Object.keys(allKeyData).forEach(key => {
							allKeyData[key].forEach(itemAll => {
								// if(this.configGroupBase[key]){
									console.log(key,this.configGroupBase[key]);
									
									this.configGroupBase[key].forEach(item => {
										if(itemAll.serviceCode==item.code){
											item.detId = itemAll.id;
											item.servicePrice = itemAll.servicePrice;
											item.discount = itemAll.discount;
											item.isExpression = itemAll.isExpression;
											item.feeExpression = itemAll.feeExpression;
											item.unit = itemAll.unit;
										}
									});
								// } 
								// else if(this.configGroupAddFee[key]){
								// 	this.configGroupAddFee[key].forEach(item => {
								// 		if(itemAll.serviceCode==item.code){
								// 			item.detId = itemAll.id;
								// 			item.servicePrice = itemAll.servicePrice;
								// 			item.discount = itemAll.discount;
								// 			item.isExpression = itemAll.isExpression;
								// 			item.feeExpression = itemAll.feeExpression;
								// 			item.unit = itemAll.unit;
								// 		}
								// 	});
								// }
							});
						});
						
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error(this.$t('hytxs0000030'));
					this.loading_load = false;
				});
		},

		submitAction() {
			let parm = [];
			Object.keys(this.configGroupBase).forEach(key => {
				this.configGroupBase[key].forEach(item => {
					parm.push({
						id: item.detId,
						opModel: this.opModel, //this.$t('i18nn_ec4432df79807d32'),
						serviceType: item.serviceType, //this.$t('i18nn_a6c64785365eba23'),
						serviceCode: item.code, //this.$t('i18nn_04082505d6c655d4'),
						servicePrice: item.servicePrice, //this.$t('i18nn_6867f16a2ac5e825'),
						discount : item.discount,//折扣
						isExpression :item.isExpression,//是否启用公式
						feeExpression :item.feeExpression,//公式
						unit: item.unit //this.$t('b6bf0a07fe26f74f')
					});
				});
			});
			// Object.keys(this.configGroupAddFee).forEach(key => {
			// 	this.configGroupAddFee[key].forEach(item => {
			// 		parm.push({
			// 			id: item.detId,
			// 			opModel: this.opModel, //this.$t('i18nn_ec4432df79807d32'),
			// 			serviceType: item.serviceType, //this.$t('i18nn_a6c64785365eba23'),
			// 			serviceCode: item.code, //this.$t('i18nn_04082505d6c655d4'),
			// 			servicePrice: item.servicePrice, //this.$t('i18nn_6867f16a2ac5e825'),
			// 			discount : item.discount,//折扣
			// 			isExpression :item.isExpression,//是否启用公式
			// 			feeExpression :item.feeExpression,//公式
			// 			unit: item.unit //this.$t('b6bf0a07fe26f74f')
			// 		});
			// 	});
			// });
			this.postData(this.$urlConfig.whCostConfigUpdate+"/"+this.selUserId, parm, () => {
				this.initData();
				this.$message.success('提交成功！');
				// this.$router.push({name:'WhPackage',query:{open:'add'}});
			});
		},
		
		//提交数据
		postData(url, formData, callback) {
			this.loading = true;
			this.$http
				.put(url, formData)
				.then(({ data }) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						if (!data.msg) {
							data.msg = this.$t('dbe331ab679cd67f');
						}
						this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					}
				})
				.catch(error => {
					console.log(error);
					console.log(this.$t('i18nn_a7d2e953195a5588'));
					this.loading = false;
					this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
						type: 'warning'
					});
				});
		},
		//
		// wh_service_model 页签类型
		// WH-STF FBZ中转
		// WH-STF-SUB  费用名称-基础
		// WH-STF-AS 费用名称-增值
		//查询数据字典
		getDicData(callback) {
			this.loading_load = true;
			//提取配置的key
			let configKeyArr = Object.keys(this.configKeyObj);
			this.$http
				.put(this.$urlConfig.HyDicQueryList, configKeyArr)
				.then(({ data }) => {
					console.log('查询数据字典，请求成功');
					console.log(data);
					this.loading_load = false;
					if (200 == data.code && data.data) {
						//将数组字典赋值
						let configGroup = data.data;
						// configKeyArr.forEach(item=>{
						// 	configGroup[item] = data.data[item];
						// });
						//区分基础和增值分组
						let configGroupBase = {};
						// let configGroupAddFee = {};

						Object.keys(configGroup).forEach(key => {
							// console.log(obj[key]) // foo
							//设置默认值
							configGroup[key].forEach(item => {
								item.serviceType = key;
								item.serviceTypeName = this.configKeyObj[key];
								item.servicePrice = 0;
								item.discount = 1;
								item.isExpression ='0';//是否启用公式
								item.feeExpression = '';//公式
								item.unit = '';
							});
							// if (key.indexOf('-SUB-') > -1) {
								configGroupBase[key] = configGroup[key];
							// } else if (key.indexOf('-AS-') > -1) {
							// 	configGroupAddFee[key] = configGroup[key];
							// }
						});

						this.configGroupBase = configGroupBase;
						console.log('configGroupBase22', configGroupBase);
						// this.configGroupAddFee = configGroupAddFee;
						
						callback();
					} else {
						if (!data.msg) {
							data.msg = this.$t('hytxs0000032');
						}
						this.$message.warning(data.msg);
					}
				})
				.catch(error => {
					console.log(error);
					this.loading_load = false;
					console.log('查询数据字典接口，请求失败');
					this.$message.error(this.$t('hytxs0000033'));
				});
		}
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.costTable {
	width: 100%;
	font-size: 14px;
	line-height: 180%;
}
.emphasize {
	background-color: #e2eef5;
	color: #333;
	font-size: 16px;
	font-weight: bold;
	line-height: 220%;
}

</style>
